.clearfix {
    &::before,&::after { content: ''; display: table}
    &::after {clear: both}
}

.gradient(@type; @colors; @dir: 0deg; @prefixes: webkit, moz, ms, o; @index: length(@prefixes)) when (@index > 0) {
    .gradient(@type; @colors; @dir; @prefixes; (@index - 1));
 
    @prefix  : extract(@prefixes, @index);
    @dir-old : 90 - (@dir);
 
    background-image: ~"-@{prefix}-@{type}-gradient(@{dir-old}, @{colors})";
  & when ( @index = length(@prefixes) ) {
        background-image: ~"@{type}-gradient(@{dir}, @{colors})";
    }
}
