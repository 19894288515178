// Social icons
@default-scicon-color: transparent;
.radius-social .social-icon {
    background: #ccc;
    margin-bottom: 5px;
    border-radius: 100%;
    overflow: hidden;
}
.social-icon {
    display: inline-block;
    padding-top:0;
    padding-bottom: 0;
    color: #333;
    a {
        color: #333;
    }
    &:hover {
        color: #fff;
        a {
            color: #fff;
        }
    }
    .fa-facebook, .fa-facebook-f {
        padding:10px 14px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-facebook:hover, .fa-facebook-f:hover {
        background-color: #3d5b99;
    }
    .fa-twitter {
        padding:10px 12px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-twitter:hover {
        background-color: #00aced;
    }
    .fa-rss {
        padding:10px 14px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-rss:hover {
        background-color: #eb8231;
    }
    .fa-youtube {
        padding:10px 14px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-youtube:hover {
        background-color: #e64a41;
    }
    .fa-linkedin {
        padding:10px 14px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-linkedin:hover {
        background-color: #0073a4;
    }
    .fa-google-plus, .fa-google-plus-g {
        padding:10px 9px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition: .5s;
        background-color: @default-scicon-color;
    }
    .fa-google-plus:hover, .fa-google-plus-g:hover {
        background-color: #e25714;
    }
}
