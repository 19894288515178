.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}
.clearfix::after {
  clear: both;
}
.backtotop {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 10px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  margin: 0;
  transition: all ease .3s;
  opacity: 0;
  bottom: 0px;
}
.backtotop::before,
.backtotop::after {
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin: auto;
  transition: all ease .3s;
}
.backtotop > span {
  opacity: 0;
}
.backtotop:hover::before,
.backtotop:hover::after {
  top: 30%;
}
.backtotop.is-visible {
  bottom: 15px;
  opacity: 1;
}
.backtotop.is-visible::before {
  transform: rotate(45deg) translateX(4px);
}
.backtotop.is-visible::after {
  transform: rotate(-45deg) translateX(-4px);
}
/* ==========================================================================
   Navigation
   ========================================================================== */
.navigation {
  zoom: 1;
  display: inline-block;
  z-index: 99;
  position: relative;
  vertical-align: top;
}
.fa.nav-right {
  background: #016833;
  color: #fff;
  min-width: 25px;
  min-height: 25px;
  line-height: 25px;
  border-radius: 100%;
  position: fixed;
  right: 10px;
  top: 10px;
  text-align: center;
}
.navigation .navigation_icon {
  display: none;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  text-align: center;
}
.navigation .navigation_icon:before,
.navigation .navigation_icon:after {
  content: "  ";
}
.navigation .navigation_icon:before,
.navigation .navigation_icon:after,
.navigation .navigation_icon > span {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #016833;
  margin: 5px auto;
}
.navigation:before,
.navigation:after {
  content: " ";
  display: table;
}
.navigation:after {
  clear: both;
}
.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.navigation a {
  text-decoration: none;
}
.navigation ul {
  text-align: center;
}
.navigation ul > li {
  display: inline-block;
}
.navigation ul > li > a,
.navigation ul > li > span {
  display: block;
  padding: 5px 15px;
}
.navigation > ul > li > a,
.navigation > ul > li > span {
  display: block;
  padding: 8px 30px;
}
/* PC
  */
@media (min-width: 768px) {
  /* BEFORE HEIGHT TO PADDING */
  .navigation > ul > li.hasSub {
    position: relative;
  }
  .navigation > ul > li.hasSub:hover:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 15px;
    background: transparent;
    position: absolute;
    top: 100%;
  }
  .fa.nav-right {
    display: none;
  }
  .navigation ul > li .sub-icon {
    display: block;
    position: absolute;
    top: 48%;
    right: 6px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: 0;
    height: 0;
    text-indent: -999999px;
    border-top: 4px dashed #999;
    border-top: 4px solid\9;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .navigation ul > li > ul > li .sub-icon {
    display: none;
  }
  .navigation ul > li ul {
    position: absolute;
    margin-top: 8px;
    min-width: 180px;
    text-align: left;
    background: #fff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
    backface-visibility: hidden;
    transform: translateY(20px);
    border: 0px solid transparent;
    border-top: 2px solid #f15b29;
    z-index: 1;
  }
  .navigation ul > li ul:before {
    content: " ";
    border-bottom: 4px dashed #f15b29;
    border-bottom: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    left: 20px;
    top: -6px;
  }
  .navigation ul > li ul > li {
    display: block;
    float: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
  }
  .navigation ul > li ul > li:last-child {
    border-bottom: none;
  }
  .navigation ul > li:hover > ul {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: inherit;
  }
  .navigation ul > li > ul > li ul {
    left: 100%;
    top: 0;
    border-top: 0;
    border-left: 0px solid #eaeaea;
  }
  .navigation ul > li > ul > li ul:before {
    transform: rotate(-90deg);
    left: -6px;
    top: 3px;
    border-color: transparent  transparent transparent transparent;
  }
  .nav-right.navigation ul > li > ul,
  .pull-right .navigation ul > li > ul {
    left: auto;
    right: 0;
  }
  .nav-right.navigation ul > li ul:before,
  .pull-right .navigation ul > li ul:before {
    left: auto;
    right: 20px;
  }
}
/* ----------------
      Mobile
   ------------------*/
@media (max-width: 768px) {
  .navigation .navigation_icon {
    display: block;
    position: fixed;
    left: 5px;
    top: 5px;
    background: transparent;
    z-index: 9999;
  }
  .nav-right.navigation .navigation_icon {
    left: auto;
    right: 5px;
  }
  .navigation > ul {
    position: fixed;
    display: block;
    top: 0;
    left: -220px;
    bottom: 0;
    height: 100%;
    width: 220px;
    background: #fff;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  .nav-right.navigation > ul {
    left: auto;
    right: -220px;
  }
  .navigation > ul > li {
    float: none;
    display: block;
    text-align: left;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 10px 30px;
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
  }
  .navigation > ul > li ul {
    text-align: left;
  }
  .navigation > ul > li ul > li {
    display: block;
  }
  .navigation > ul > li ul > li > a {
    padding-left: 30px;
    display: block;
    background: #e8e8e8;
    border-bottom: 1px solid #eaeaea;
  }
  .navigation .navigation_icon:before,
  .navigation .navigation_icon:after,
  .navigation .navigation_icon > span {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
  }
  .navigation .navigation_icon:before {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navigation .navigation_icon:after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navigation .navigation_icon > span {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
  .navigation > ul,
  .navigation .navigation_icon {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .navigation.nav-open > ul {
    left: 0;
    z-index: 9999;
    overflow: auto;
  }
  .nav-right.navigation.nav-open > ul {
    left: auto;
    right: 0;
  }
  .navigation.nav-open .navigation_icon {
    left: 220px;
    z-index: 9999;
  }
  .nav-right.navigation.nav-open .navigation_icon {
    left: auto;
    right: 220px;
  }
  .navigation.nav-open .navigation_icon:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 10px;
  }
  .navigation.nav-open .navigation_icon:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: -14px;
  }
  .navigation.nav-open .navigation_icon > span {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}
.backdrop_nav {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
@media (min-width: 768px) {
  .navigation ul > li .sub-icon {
    display: none;
  }
  li.hasSub > a {
    position: relative;
  }
  li.hasSub > a:after {
    content: "";
    position: relative;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    display: inline-block;
    margin-left: 4px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
@media (max-width: 768px) {
  .navigation > ul > li:first-child {
    border-top: 1px solid #eaeaea;
  }
  .navigation .hasSub {
    position: relative;
  }
  .navigation .hasSub > .sub-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 10px;
    padding-left: 17px;
    width: 40px;
    height: 37px;
    background: transparent;
    border-left: 1px solid #eaeaea;
    border-bottom: none;
  }
  .navigation .hasSub > ul {
    display: none;
  }
  .navigation .hasSub.hasSub_on > ul {
    display: block;
  }
  .navigation > ul > li {
    position: relative;
    width: 100%;
  }
  .navigation > ul > li > ul {
    display: none;
    padding: 0;
  }
  .navigation > ul > li > ul li > a {
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    color: #666;
  }
}
@media (max-width: 768px) {
  .navi-wrapper .container {
    position: relative;
    z-index: 9;
  }
  .navigation > ul {
    padding-top: 90px !important;
    background: #fff url(../images/logo.png) no-repeat center 20px / 70% auto;
  }
}
@media (min-width: 768px) {
  .navi-wrapper .container {
    position: relative;
    z-index: 9;
  }
  .navigation > ul > li {
    margin-left: -3px;
  }
  .navigation > ul > li:first-child {
    margin-left: 0;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 10px;
    position: relative;
  }
  .navigation > ul > li > a::before,
  .navigation > ul > li > span::before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0px;
    margin: auto;
    height: 2px;
    background: #f15b29;
    transition: all .3s ease;
  }
  .navigation > ul > li.active > a,
  .navigation > ul > li.active > span,
  .navigation > ul > li > a:hover,
  .navigation > ul > li > span:hover {
    background: transparent;
  }
  .navigation > ul > li.active > a::before,
  .navigation > ul > li.active > span::before,
  .navigation > ul > li > a:hover::before,
  .navigation > ul > li > span:hover::before {
    width: 100%;
  }
  .navigation > ul > li.hasSub.mega-menu > ul {
    left: 0;
    width: 400px;
  }
  .navigation > ul > li.hasSub.mega-menu > ul > li {
    display: block;
    float: left;
    width: calc(200px - 0px);
  }
  .navigation > ul > li.hasSub.mega-menu.three-items-menu > ul {
    width: 600px;
  }
  .navigation > ul > li.hasSub.mega-menu.three-items-menu > ul > li {
    width: calc(200px - 0px);
  }
}
@media (min-width: 768px) {
  .navigation > ul > li a,
  .navigation > ul > li span {
    color: #333;
    transition: all .3s ease;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    text-transform: uppercase;
    font-weight: 500;
  }
  .navigation > ul > li > ul > li a:hover,
  .navigation > ul > li > ul > li span:hover {
    color: #fff;
    background: #f15b29;
  }
  .navigation > ul > li > a:hover,
  .navigation > ul > li > span:hover {
    color: #666;
  }
}
@media (max-width: 1000px) {
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 10px 5px;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 8px 5px;
    color: #016833;
    font-size: 14px;
    color: #333;
  }
}
.carousel-icon-next {
  max-width: 256px;
  max-height: 256px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjEyOHB4IiBoZWlnaHQ9IjEyOHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzYwLjczMSwyMjkuMDc1bC0yMjUuMS0yMjUuMWMtNS4zLTUuMy0xMy44LTUuMy0xOS4xLDBzLTUuMywxMy44LDAsMTkuMWwyMTUuNSwyMTUuNWwtMjE1LjUsMjE1LjUgICBjLTUuMyw1LjMtNS4zLDEzLjgsMCwxOS4xYzIuNiwyLjYsNi4xLDQsOS41LDRjMy40LDAsNi45LTEuMyw5LjUtNGwyMjUuMS0yMjUuMUMzNjUuOTMxLDI0Mi44NzUsMzY1LjkzMSwyMzQuMjc1LDM2MC43MzEsMjI5LjA3NXogICAiIGZpbGw9IiNjY2NjY2MiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
}
.carousel-icon-prev {
  max-width: 256px;
  max-height: 256px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMjU2cHgiIGhlaWdodD0iMjU2cHgiPgogIDxnPgogICAgPHBhdGggZD0ibTg4LjYsMTIxLjNjMC44LDAuOCAxLjgsMS4yIDIuOSwxLjJzMi4xLTAuNCAyLjktMS4yYzEuNi0xLjYgMS42LTQuMiAwLTUuOGwtNTEtNTEgNTEtNTFjMS42LTEuNiAxLjYtNC4yIDAtNS44cy00LjItMS42LTUuOCwwbC01NCw1My45Yy0xLjYsMS42LTEuNiw0LjIgMCw1LjhsNTQsNTMuOXoiIGZpbGw9IiNjY2NjY2MiLz4KICA8L2c+Cjwvc3ZnPgo=');
}
.carousel {
  background: transparent;
}
.carousel img {
  max-width: 80%;
}
.carousel .carousel-control-next .carousel-control-next-icon {
  max-width: 256px;
  max-height: 256px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjEyOHB4IiBoZWlnaHQ9IjEyOHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzYwLjczMSwyMjkuMDc1bC0yMjUuMS0yMjUuMWMtNS4zLTUuMy0xMy44LTUuMy0xOS4xLDBzLTUuMywxMy44LDAsMTkuMWwyMTUuNSwyMTUuNWwtMjE1LjUsMjE1LjUgICBjLTUuMyw1LjMtNS4zLDEzLjgsMCwxOS4xYzIuNiwyLjYsNi4xLDQsOS41LDRjMy40LDAsNi45LTEuMyw5LjUtNGwyMjUuMS0yMjUuMUMzNjUuOTMxLDI0Mi44NzUsMzY1LjkzMSwyMzQuMjc1LDM2MC43MzEsMjI5LjA3NXogICAiIGZpbGw9IiNjY2NjY2MiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  transition: all ease .3s;
  border-radius: 100%;
}
.carousel .carousel-control-next:hover .carousel-control-next-icon {
  background-color: rgba(255, 255, 255, 0.1);
  background-size: 50% 50%;
  padding: 20px;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
}
.carousel .carousel-control-prev .carousel-control-prev-icon {
  max-width: 256px;
  max-height: 256px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMjU2cHgiIGhlaWdodD0iMjU2cHgiPgogIDxnPgogICAgPHBhdGggZD0ibTg4LjYsMTIxLjNjMC44LDAuOCAxLjgsMS4yIDIuOSwxLjJzMi4xLTAuNCAyLjktMS4yYzEuNi0xLjYgMS42LTQuMiAwLTUuOGwtNTEtNTEgNTEtNTFjMS42LTEuNiAxLjYtNC4yIDAtNS44cy00LjItMS42LTUuOCwwbC01NCw1My45Yy0xLjYsMS42LTEuNiw0LjIgMCw1LjhsNTQsNTMuOXoiIGZpbGw9IiNjY2NjY2MiLz4KICA8L2c+Cjwvc3ZnPgo=');
  transition: all ease .3s;
  border-radius: 100%;
}
.carousel .carousel-control-prev:hover .carousel-control-prev-icon {
  background-color: rgba(255, 255, 255, 0.1);
  background-size: 50% 50%;
  padding: 20px;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
}
.carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transition: all ease .3s;
}
.carousel .carousel-indicators li.active {
  width: 14px;
  height: 14px;
  transform: translateY(-3px);
}
.slide-caption {
  padding: 15px;
}
.slide-caption,
.slide-caption a {
  color: #fff;
}
.slide-caption h2,
.slide-caption h3,
.slide-caption h4 {
  font-size: 1.813rem;
  font-weight: 200;
}
@media screen and (min-width: 768px) {
  .carousel {
    padding: 30px 15px;
  }
  .carousel .carousel-control-prev {
    left: -120px;
  }
  .carousel .carousel-control-next {
    right: -120px;
  }
}
.slide-owl-carousel {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 10px;
}
.main-head .block {
  padding: 15px;
  display: block;
}
.main-head .block .cover-item {
  width: 100%;
  display: inline-block;
  float: left;
  margin-bottom: 15px;
}
.main-head .block img {
  max-width: 100%;
  display: block;
  margin: auto;
}
.main-head .block .title {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}
.main-head .block .short-description {
  color: #333;
}
.main-head .block .detail-item {
  padding: 0;
}
@media (min-width: 768px) {
  .main-head .block .cover-item {
    width: 46.666%;
    margin-right: 15px;
  }
}
.radius-social .social-icon {
  background: #ccc;
  margin-bottom: 5px;
  border-radius: 100%;
  overflow: hidden;
}
.social-icon {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  color: #333;
}
.social-icon a {
  color: #333;
}
.social-icon:hover {
  color: #fff;
}
.social-icon:hover a {
  color: #fff;
}
.social-icon .fa-facebook,
.social-icon .fa-facebook-f {
  padding: 10px 14px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-facebook:hover,
.social-icon .fa-facebook-f:hover {
  background-color: #3d5b99;
}
.social-icon .fa-twitter {
  padding: 10px 12px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-twitter:hover {
  background-color: #00aced;
}
.social-icon .fa-rss {
  padding: 10px 14px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-rss:hover {
  background-color: #eb8231;
}
.social-icon .fa-youtube {
  padding: 10px 14px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-youtube:hover {
  background-color: #e64a41;
}
.social-icon .fa-linkedin {
  padding: 10px 14px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-linkedin:hover {
  background-color: #0073a4;
}
.social-icon .fa-google-plus,
.social-icon .fa-google-plus-g {
  padding: 10px 9px;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  background-color: transparent;
}
.social-icon .fa-google-plus:hover,
.social-icon .fa-google-plus-g:hover {
  background-color: #e25714;
}
.top {
  width: 100%;
}
.top::before,
.top::after {
  content: '';
  display: table;
}
.top::after {
  clear: both;
}
.top,
.top a {
  color: rgba(51, 51, 51, 0.8);
  font-size: 12px;
  line-height: 20px;
}
.top .top-element {
  display: inline-block;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.top .top-element:first-child {
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
.top .material-icons {
  top: 2px;
}
.top ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top ul li {
  display: inline-block;
}
.top ul li > span,
.top ul li > a {
  display: inline-block;
  padding: 6px 12px;
}
.top ul li > span.separate,
.top ul li > a.separate {
  padding: 0;
  line-height: 18px;
  min-height: 18px;
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.top ul li .btn {
  border-radius: 15px;
  padding: 4px 12px;
  vertical-align: middle;
  display: inline-block;
  margin-top: 2.5px;
}
.top p {
  margin: 0;
}
.partners {
  background: transparent;
}
.owl-carousel .owl-item .partner-item {
  text-align: center;
  display: block;
}
.owl-carousel .owl-item .partner-item img {
  max-width: 100%;
  display: block;
  margin: auto;
}
.image-container {
  position: relative;
}
.image-container .featured-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  margin: auto;
  width: 50%;
  height: 50%;
  transition: all ease .3s;
  background: rgba(0, 0, 0, 0.3);
}
.image-container:hover .featured-image-overlay {
  opacity: 1;
  width: 100%;
  height: 100%;
}
.image-container .featured-image-icon {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  color: #fff;
  font-size: 32px;
}
.news-post {
  padding: 10px 0px 0px;
  margin-bottom: 10px;
  transition: all ease .3s;
}
.news-post::before,
.news-post::after {
  content: '';
  display: table;
}
.news-post::after {
  clear: both;
}
.news-post.line-bottom {
  border-bottom: 1px solid #eaeaea;
}
.news-post.line-bottom:last-child {
  border-bottom: none;
}
.news-post a {
  color: #333;
}
.news-post.small-post .news-post-content .post-title {
  font-size: inherit;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 0;
}
.news-post.small-post .news-post-image-link {
  max-width: 100px;
  float: left;
  margin-right: 15px;
}
.news-post.list-style-post {
  padding: 0;
  margin: 0;
}
.news-post.list-style-post.line-bottom {
  border-bottom: none;
}
.news-post.list-style-post.line-bottom .post-title {
  border-bottom: 1px dotted #ccc;
  display: inline-block;
  padding: 6px 0;
}
.news-post.list-style-post.line-bottom:last-child .post-title {
  border-bottom: none;
}
.news-post.list-style-post .list-icon {
  font-size: 4px;
  color: #016833;
  line-height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}
.news-post .post-sapo {
  font-weight: 600;
  margin-bottom: 10px;
  font-style: italic;
  border-bottom: 1px dotted #eaeaea;
  padding-bottom: 10px;
}
.news-post .news-post-image-link {
  float: left;
  margin-right: 15px;
  transition: all ease .3s;
}
.news-post .news-post-image-link::before,
.news-post .news-post-image-link::after {
  content: '';
  display: table;
}
.news-post .news-post-image-link::after {
  clear: both;
}
.news-post .news-post-image-link img {
  max-width: 100%;
}
.news-post .news-post-content .post-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
  margin-top: 0;
}
.post-meta-container {
  font-size: smaller;
  color: #999;
  margin-bottom: 10px;
  font-style: italic;
}
.post-meta-container .post-meta-item {
  margin-right: 10px;
}
.read-more-button {
  font-size: smaller;
  font-style: italic;
  display: inline-block;
  margin-bottom: 10px;
}
.read-more-button,
.read-more-button a {
  color: #999;
}
.read-more-button.btn-readmore-primary > span,
.read-more-button.btn-readmore-primary > a {
  background: #016833;
  padding: 4px 7px;
  border-radius: 3px;
  color: #fff;
}
@media (min-width: 768px) {
  .border-sm-right {
    border-right: 1px dotted #eaeaea;
  }
  .news-post .news-post-image-link {
    max-width: 200px;
  }
  .news-post.transform-hover:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }
  .news-post.transform-hover:hover .news-post-image-link {
    transform: scale(0.9);
  }
}
@media (max-width: 767px) {
  .news-post .news-post-image-link {
    display: block;
    float: none;
    margin-right: 0;
  }
  .news-post .news-post-image-link img {
    max-width: 100%;
    width: 100%;
  }
}
/*
news box 
*/
.news_box_image_link {
  float: left;
  margin-right: 16px;
}
.news_box_item img {
  opacity: 0;
  -o-transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.images_trans_disabled .news_box_item img {
  opacity: 1;
}
.news_box_item img.img_trans {
  opacity: 1;
  -o-transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.news_box_item_content .post_title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 13px;
}
.news_box_item:hover .news_box_item_content .post_title a {
  color: #FF7D0E;
}
.news_box_item_content .post_meta_container {
  font-size: 13px;
  line-height: 18px;
  color: #999;
  margin-bottom: 10px;
  font-style: italic;
}
.news_box_item_content .post_meta_container i {
  color: #777;
}
.news_box_item_content .post_meta_container .post_meta_item {
  margin-right: 8px;
}
.news_box_item_content .post_meta_container .post_meta_item:last-child {
  margin-right: 0;
}
.news_box_item_content .post_meta_container a {
  color: #999;
}
.news_box_item_content .post_meta_container a:hover {
  color: #777;
  border-bottom: 1px dashed #777;
}
.news_box_item_content .news_box_desc {
  font-size: 13px;
  line-height: 20px;
  color: #222;
  margin-top: 0;
  margin-bottom: 6px;
}
.news_box_item_content .read_more_button a {
  font-size: 13px;
  color: #333;
  font-weight: 600;
  display: inline-block;
  font-style: italic;
}
.news_box_item_content .read_more_button a:hover {
  color: #FF7D0E;
}
.pagination .page-item.active .page-link {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
  background-color: #4285f4;
  color: #fff;
}
.pagination .page-item.active .page-link:hover {
  background-color: #4285f4;
}
.pagination .page-item.disabled .page-link {
  color: #868e96;
}
.pagination .page-item .page-link {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  outline: 0;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  color: #212529;
}
.pagination .page-item .page-link:hover {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
  background-color: #eee;
}
.pagination .page-item .page-link:focus {
  background-color: transparent;
}
.pagination.pagination-lg .page-item .page-link {
  font-size: 1rem;
}
.pagination.pagination-sm .page-item .page-link {
  font-size: 0.8rem;
}
.pagination.pagination-circle .page-item .page-link {
  margin-left: 2px;
  margin-right: 2px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.pagination.pagination-circle .page-item .page-link:hover {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.pagination.pagination-circle .page-item.active .page-link {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.pagination.pg-blue .page-item.active .page-link {
  background-color: #4285f4;
}
.pagination.pg-blue .page-item.active .page-link:hover {
  background-color: #4285f4;
}
.pagination.pg-red .page-item.active .page-link {
  background-color: #ff3547;
}
.pagination.pg-red .page-item.active .page-link:hover {
  background-color: #ff3547;
}
.pagination.pg-teal .page-item.active .page-link {
  background-color: #2bbbad;
}
.pagination.pg-teal .page-item.active .page-link:hover {
  background-color: #2bbbad;
}
.pagination.pg-dark-grey .page-item.active .page-link {
  background-color: #37474f;
}
.pagination.pg-dark-grey .page-item.active .page-link:hover {
  background-color: #37474f;
}
.pagination.pg-dark .page-item.active .page-link {
  background-color: #2e2e2e;
}
.pagination.pg-dark .page-item.active .page-link:hover {
  background-color: #2e2e2e;
}
.pagination.pg-blue-grey .page-item.active .page-link {
  background-color: #3f729b;
}
.pagination.pg-blue-grey .page-item.active .page-link:hover {
  background-color: #3f729b;
}
.pagination.pg-amber .page-item.active .page-link {
  background-color: #ff6f00;
}
.pagination.pg-amber .page-item.active .page-link:hover {
  background-color: #ff6f00;
}
.pagination.pg-purple .page-item.active .page-link {
  background-color: #5e35b1;
}
.pagination.pg-purple .page-item.active .page-link:hover {
  background-color: #5e35b1;
}
.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
}
.breadcrumb-wrapper {
  background: #f1f1f1;
  font-size: small;
}
.breadcrumb-wrapper i {
  margin-right: 5px;
}
.breadcrumb-wrapper a {
  color: #333;
}
.breadcrumb-wrapper a:hover {
  text-decoration: none;
  color: #016833;
}
.breadcrumb-dot .breadcrumb-item + .breadcrumb-item::before {
  content: "•";
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
.page {
  overflow: hidden;
}
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
}
body .cover-home {
  position: relative;
  overflow: hidden;
  background: #016833;
  background-size: 400% 400%;
  min-height: 100vh;
  animation: BackgroundGradient 15s ease infinite;
}
body .cover-home::before {
  pointer-events: none;
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../images/slide-wrapper-bg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% 70%;
}
body .cover-home::after {
  pointer-events: none;
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../images/starts.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 60%;
  transform: rotate(0deg);
  animation: BackgroundRepeated 25s linear infinite;
  transition: all linear .3s;
}
@keyframes BackgroundGradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes BackgroundFade {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 150% 150%;
  }
  100% {
    background-size: 100% 100%;
  }
}
@keyframes BackgroundRepeated {
  0% {
    opacity: 1;
    background-size: 60%;
    background-position: 100% 30%;
    transform: rotate(0deg);
  }
  50% {
    opacity: 0;
    background-size: 80%;
    background-position: -100% 30%;
    transform: rotate(45deg);
  }
  100% {
    opacity: 1;
    background-size: 60%;
    background-size: 100% 30%;
    transform: rotate(0deg);
  }
}
a:hover {
  text-decoration: none;
}
.isMobile {
  display: none !important;
}
@media (max-width: 768px) {
  .isMobile {
    display: inline-block !important;
  }
}
/** LAYOUT */
.main {
  background: #f7f8fa;
}
.main-wrapper {
  background: #fff;
  padding-top: 1px;
}
.main-head {
  border-bottom: 1px solid #eaeaea;
}
.main-head.project-hot {
  background: #fff;
  padding-top: 15px;
}
.main-head.project-hot .block {
  background: transparent;
}
.main-head.project-hot .block .cover-item {
  width: 100%;
}
@media (min-width: 768px) {
  .home .border-sm-right {
    background: #fff;
  }
}
.top-layout {
  background: #f7f8f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.logo {
  margin: 8px 0;
  padding: 0;
  display: inline-block;
  max-width: 130px;
}
.logo img {
  max-width: 100%;
  max-height: 50px;
}
.header-layout {
  background: #fff;
}
@media (min-width: 768px) {
  .header-layout {
    position: relative;
    z-index: 99999;
  }
}
@media (max-width: 768px) {
  .header-layout {
    text-align: center;
  }
}
.navigation.nav-left {
  display: inline-block;
}
.navigation.nav-left > ul {
  text-align: left;
}
.navi-layout .navigation > ul > li > span,
.navi-layout .navigation > ul > li > a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding: 9px 10px;
}
@media (min-width: 768px) {
  .navi-layout {
    position: relative;
    z-index: 1024;
    background: #016833;
    background: -moz-linear-gradient(left, #016833 0%, #3c7922 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #016833 0%, #3c7922 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #016833 0%, #3c7922 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@primary-color', endColorstr='@light-primary-color', GradientType=1);
    /* IE6-9 */
  }
  .navi-layout .navigation > ul > li > span,
  .navi-layout .navigation > ul > li > a {
    color: #fff;
  }
  .navi-layout .navigation > ul > li > span:hover,
  .navi-layout .navigation > ul > li > a:hover {
    color: #fff;
    background: #f15b29;
  }
  .navi-layout .navigation > ul > li.active > span,
  .navi-layout .navigation > ul > li.active > a {
    color: #fff;
    background: #f15b29;
  }
}
.nav-right > ul > li .fa {
  font-size: .65em;
  line-height: 2;
  vertical-align: middle;
  display: inline-block;
}
.navigation > ul > li.btn-wrapper {
  padding: 0;
  background: transparent;
  margin-right: 4px;
}
.navigation > ul > li.btn-wrapper a {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  padding: 6px 12px;
}
.navigation > ul > li.btn-wrapper.btn-primary > a {
  border: 1px solid #016833;
  background: #016833;
  color: #ffffff;
}
.navigation > ul > li.btn-wrapper.btn-success > a {
  border: 1px solid #8BC34A;
  background: #8BC34A;
  color: #ffffff;
}
.navigation > ul > li.btn-wrapper {
  padding: 0;
  background: transparent;
  margin-right: 4px;
}
.navigation > ul > li.btn-wrapper button {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  padding: 6px 12px;
  display: inline-block;
  font-size: .85em;
}
.navigation > ul > li.btn-wrapper button.btn-primary {
  border: 1px solid #016833;
  background: #016833;
  color: #ffffff;
}
.navigation > ul > li.btn-wrapper button.btn-success {
  border: 1px solid #8BC34A;
  background: #8BC34A;
  color: #ffffff;
}
.section-main {
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .navi-wrapper {
    text-align: center;
  }
  .logo {
    margin: 0;
    padding: 4px 0;
    display: inline-block;
    width: calc(100% + 30px);
    margin: 0 -15px;
    margin-bottom: 10px;
  }
  .logo img {
    max-height: 33px;
  }
}
.fix-top.isfixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1420;
  box-shadow: 0 2px 3px #00000014;
}
.fix-top.isfixed .logo img {
  max-height: 50px;
}
@media (min-width: 768px) {
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    text-transform: none;
    font-weight: 400;
  }
  .navigation > ul > li.active > a,
  .navigation > ul > li.active > span,
  .navigation > ul > li > a:hover,
  .navigation > ul > li > span:hover {
    color: #016833;
  }
  .navigation > ul > li.active > a::before,
  .navigation > ul > li.active > span::before,
  .navigation > ul > li > a:hover::before,
  .navigation > ul > li > span:hover::before {
    width: 100%;
    display: none;
  }
  .navigation.nav-right > ul > li > a,
  .navigation.nav-right > ul > li > span {
    text-transform: none;
    font-weight: normal;
  }
  .navigation.nav-right > ul > li.active > a,
  .navigation.nav-right > ul > li.active > span {
    color: #333;
  }
}
.btn-brand {
  color: #fff;
  background-color: #016833;
  border-color: #016833;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 30px;
}
.btn-brand:hover {
  background-color: #f15b29;
  color: #fff;
  border-color: #f15b29;
  box-shadow: 0 2px 3px #0000002e;
}
.btn-item {
  display: inline-block;
  margin: 10px 5px 0;
}
@media (min-width: 768px) {
  .slider-layout .carousel {
    padding-top: 39px;
    padding-bottom: 39px;
  }
}
@media (max-width: 768px) {
  .slider-layout {
    overflow: hidden;
  }
}
.slider-layout .btn-primary {
  color: #fff;
  background-color: #cb9a16;
  border-color: #cb9a16;
  padding: 3px 12px;
  font-size: 12px;
  border-radius: 30px;
}
.slider-layout .btn-primary:hover {
  background-color: #d6ac3d;
  color: #fff;
  border-color: #d6ac3d;
  box-shadow: 0 2px 3px #0000002e;
}
.sidebar .table-tygia {
  font-size: 12px;
}
@media (min-width: 768px) {
  .module-title.solid-color > a {
    background: #f15b29;
    color: #fff;
    padding: 6px 15px;
    font-size: small;
    width: 100%;
  }
  .module-title.solid-color > a::before,
  .module-title.solid-color > a::after {
    content: '';
    display: table;
  }
  .module-title.solid-color > a::after {
    clear: both;
  }
  .module-title.solid-color > a:focus,
  .module-title.solid-color > a:hover {
    color: #ffb787;
  }
}
/* UTILITY */
.landscape_image {
  position: relative;
  padding-bottom: 56.25%;
}
.landscape_image img {
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}
ul.list-head {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 445px;
  overflow: auto;
}
ul.list-head > li > a.title,
ul.list-head > li > span.title {
  display: inline-block;
  padding: 6px 15px;
  font-weight: 500;
  color: #333;
}
ul.list-head > li > a.meta-info-item,
ul.list-head > li > span.meta-info-item {
  padding: 6px 15px;
  color: #999;
}
/* ICON*/
.inc-icon {
  display: inline-block;
  vertical-align: middle;
  background: transparent url(../images/icon-play.png) no-repeat center top / 100% 100%;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.inc-icon.inc-play {
  margin-left: -7px;
}
/* MENU*/
.menu-layout {
  background-color: rgba(1, 104, 51, 0.7);
}
@media (min-width: 768px) {
  .top-layout {
    margin-bottom: 0px;
  }
  .width-sm-320px {
    width: 320px;
    margin: 0 auto;
  }
  .border-sm-right {
    border-right: 1px solid #eaeaea;
  }
}
@media (max-width: 768px) {
  .top-layout .top {
    display: none;
  }
}
/* OWL THEME */
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 33px;
  width: 40px;
  height: 40px;
  color: #2f9ed4;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 3px #0000002e;
  opacity: .3;
}
.owl-carousel .owl-nav button.owl-prev:active,
.owl-carousel .owl-nav button.owl-prev:active,
.owl-carousel .owl-nav button.owl-next:active,
.owl-carousel .owl-nav button.owl-next:active,
.owl-carousel .owl-nav button.owl-prev:focus,
.owl-carousel .owl-nav button.owl-prev:focus,
.owl-carousel .owl-nav button.owl-next:focus,
.owl-carousel .owl-nav button.owl-next:focus {
  outline: none;
}
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  opacity: 1;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev {
  left: 0;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-next {
  right: 0;
}
@media (min-width: 768px) {
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-prev {
    left: -30px;
  }
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-next {
    right: -30px;
  }
}
.user-avatar {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  width: 36px;
  height: 36px;
  position: relative;
  vertical-align: middle;
  border-radius: 100%;
  margin-right: 5px;
  overflow: hidden;
  border: 2px solid #d4f0fb;
}
.user-avatar img {
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}
/* FORM BUTTON */
.button-tab {
  border-radius: 4px 4px 0 0;
  min-width: 100px;
  border-bottom: none;
}
.search-form {
  padding: 0 15px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: 620px;
}
.search-form select {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
  position: absolute;
  height: 100%;
  background: transparent;
  border: none;
  padding: 0 15px;
  width: 120px;
  border-right: 2px solid #016833;
}
.search-form .form {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.search-form .search-input {
  background: #fff;
  border: 2px solid #016833;
  min-height: 32px;
  padding: 0 10px;
  padding-left: 135px;
  line-height: 30px;
  width: 100%;
  border-radius: 6px;
  color: #666;
  font-size: 12px;
}
.search-form .search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  font-size: 12px;
  opacity: 1;
  /* Firefox */
}
.search-form .search-button {
  position: absolute;
  right: 15px;
  top: 0;
  border: none;
  height: 34px;
  background: #016833;
  color: #fff;
  padding: 0 16px;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}
.search-form .search-button:hover {
  background: #f15b29;
}
.banner-text {
  background: #81858e;
  color: #fff;
  text-align: center;
  padding: 30px 0;
}
.banner-text .block-title,
.banner-text .text-block {
  margin: auto;
  width: 680px;
  max-width: 100%;
}
.banner-text .block-title {
  margin-top: 15px;
  margin-bottom: 20px;
}
.banner-text .block-title .title {
  font-weight: 400;
  font-size: 1.8rem;
}
.banner-text .text-block {
  margin-bottom: 20px;
}
.block-title {
  display: inline-block;
}
.block-title .title {
  font-size: medium;
  margin-top: 10px;
}
.underline {
  border-bottom: 2px solid #016833;
}
.subtitle {
  margin: 10px 0 15px;
  color: #999;
}
/* FOOTER */
footer,
.footer {
  padding-top: 30px;
}
footer,
.footer,
footer a,
.footer a {
  color: #666;
}
footer a:hover,
.footer a:hover {
  color: #ccc;
}
footer .social-icon,
.footer .social-icon {
  background: #666;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}
footer .social-icon,
.footer .social-icon,
footer .social-icon a,
.footer .social-icon a {
  color: #fff;
}
footer .social-icon i,
.footer .social-icon i {
  padding: 8px 8px;
  min-width: 32px;
  font-size: 14px;
  text-align: center;
}
footer ul,
.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
footer ul li > span,
.footer ul li > span,
footer ul li > a,
.footer ul li > a {
  padding: 6px 0px;
  display: block;
  font-size: 15px;
}
footer ul li > span i.material-icons.list-style,
.footer ul li > span i.material-icons.list-style,
footer ul li > a i.material-icons.list-style,
.footer ul li > a i.material-icons.list-style {
  font-size: 6px;
  line-height: 20px;
  display: inline-block;
  margin: 0 10px 0px 0;
  vertical-align: top;
}
footer ul li:last-child > span,
.footer ul li:last-child > span,
footer ul li:last-child > a,
.footer ul li:last-child > a {
  border-bottom: none;
}
footer.dark,
.footer.dark {
  background: #01351a;
  padding: 30px 0 0;
}
footer.dark,
.footer.dark,
footer.dark a,
.footer.dark a {
  color: #c2cbde;
}
footer.dark.divider-bottom::before,
.footer.dark.divider-bottom::before {
  background: #fff;
}
footer.dark .copyright,
.footer.dark .copyright {
  border-top: 1px solid #dcdcdc33;
  color: #99999970;
}
footer .title,
.footer .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.05;
  text-transform: uppercase;
  margin: 10px 0 15px;
  padding-bottom: 10px;
  position: relative;
}
footer .title.divider-bottom::before,
.footer .title.divider-bottom::before {
  content: ' ';
  height: 2px;
  background: #8a8a8a8a;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
}
.copyright {
  border-top: 1px solid #dcdcdc;
  padding: 10px 0;
  font-size: small;
  color: #999999;
}
/* SUB PAGE */
.subpage {
  position: relative;
  z-index: 9999;
}
.page-title {
  margin-bottom: 0;
}
.page-title,
.module-title {
  margin-top: 15px;
  font-size: medium;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #eaeaea;
}
.page-title,
.module-title,
.page-title a,
.module-title a {
  color: #333;
}
.page-title > a,
.module-title > a,
.page-title span,
.module-title span {
  display: inline-block;
  padding: 6px 0;
  position: relative;
}
.page-title > a:hover,
.module-title > a:hover,
.page-title span:hover,
.module-title span:hover {
  text-decoration: none;
  color: #016833;
}
.page-title > a::before,
.module-title > a::before,
.page-title span::before,
.module-title span::before {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #f15b29;
}
.banner-add {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.banner-add img {
  width: 100%;
}
.banner-add.sticky-top {
  padding-top: 50px;
}
.div-padding-stiky {
  margin-bottom: -50px;
}
/* WIDGET BLOCK */
.widget-block {
  text-align: left;
  margin: 5px 0;
}
.widget-block .block-title {
  display: block;
  margin: 0;
}
.widget-block .block-title > span {
  display: block;
  padding: 2px 8px;
  font-weight: bold;
  font-size: small;
}
.widget-block .block-content {
  max-height: 60px;
  overflow: hidden;
  border-top: 1px solid #eaeaea;
  padding: 5px 0px;
}
.widget-block .block-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget-block .block-content ul > li {
  display: inline-block;
  overflow: hidden;
  border-bottom: 1px solid #eaeaea;
}
.widget-block .block-content ul > li > span,
.widget-block .block-content ul > li > a {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 1px 12px 0 0;
  max-width: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .widget-block .block-content {
    max-height: 160px;
  }
  .widget-block.vn-hnx-block .block-content ul > li,
  .widget-block.vn-hnx-block .block-content ul > li > span {
    display: block;
  }
  .widget-block.vn-hnx-block img {
    width: 100%;
  }
}
/* HEAD NEW BLOCK */
.widget-block.head-news-block .date {
  color: #f15b29;
  min-width: 50px;
  display: inline-block;
}
.widget-block.head-news-block .block-title {
  display: inline-block;
}
.widget-block.head-news-block .block-title > span {
  background: #f15b29;
  color: #fff;
}
.widget-block.head-news-block .block-content ul > li {
  width: 50%;
  float: left;
  max-height: 25px;
  line-height: 24px;
}
@media (max-width: 768px) {
  .widget-block.head-news-block .block-content ul > li {
    width: 100%;
  }
}
.icon-xs {
  background-color: #ccc;
  width: 24px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.form-panel-layout {
  max-width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.login-panel,
.register-panel {
  display: block;
  min-height: 120px;
  padding: 60px 30px 0;
  text-align: center;
  transition: all ease .3s;
}
.login-panel .title,
.register-panel .title {
  font-size: x-large;
  text-indent: -1px;
  color: #f15b29;
  font-weight: 700;
  letter-spacing: -1.4px;
}
.login-panel .icon-form-label,
.register-panel .icon-form-label {
  background: #016833;
  color: #fff;
  width: 30px;
  max-width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  overflow: hidden;
  padding: 0;
  line-height: 30px;
}
.login-panel .helper-link,
.register-panel .helper-link,
.login-panel label,
.register-panel label {
  font-size: small;
  color: #016833;
  vertical-align: middle;
}
.login-panel .helper-link + a:hover,
.register-panel .helper-link + a:hover,
.login-panel label + a:hover,
.register-panel label + a:hover {
  color: #f15b29;
}
.login-panel .helper-link,
.register-panel .helper-link {
  padding: 20px 0;
  display: inline-block;
}
.login-panel .form,
.register-panel .form {
  margin: 50px 0;
}
.login-panel .form-group,
.register-panel .form-group {
  margin: 15px 0 30px;
}
.login-panel .btn,
.register-panel .btn {
  border-radius: 20px;
  min-width: 220px;
  font-weight: 400;
}
.login-panel .btn.btn-primary,
.register-panel .btn.btn-primary {
  background-color: #016833;
  color: #fff;
  border-color: #016833;
}
.btn.btn-sm {
  border-radius: 3px;
  padding: 5px 10px;
}
.sub-nav,
.sub-nav a {
  color: #fff;
}
.sub-nav .nav-link {
  text-transform: none;
}
.home_services,
.team-carousel,
.list-course {
  padding-top: 10px;
}
.home_services img,
.team-carousel img,
.list-course img {
  width: 100%;
}
.home_services .block,
.team-carousel .block,
.list-course .block {
  position: relative;
  margin-bottom: 15px;
  transition: all ease .3s;
  background: #fff;
  padding: 0px 15px 15px;
}
.home_services .block.gray-block,
.team-carousel .block.gray-block,
.list-course .block.gray-block {
  background-color: #fff;
  margin-bottom: 30px;
  color: #666;
}
.home_services .block.gray-block .title,
.team-carousel .block.gray-block .title,
.list-course .block.gray-block .title,
.home_services .block.gray-block .title a,
.team-carousel .block.gray-block .title a,
.list-course .block.gray-block .title a {
  color: #333;
}
.home_services .block.gray-block .float-button,
.team-carousel .block.gray-block .float-button,
.list-course .block.gray-block .float-button {
  background: #e8eaec;
  color: #016833;
}
.home_services .block.gray-block.active,
.team-carousel .block.gray-block.active,
.list-course .block.gray-block.active,
.home_services .block.gray-block:hover,
.team-carousel .block.gray-block:hover,
.list-course .block.gray-block:hover {
  background-color: #f7f8fa;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.3);
}
.home_services .block.gray-block.active,
.team-carousel .block.gray-block.active,
.list-course .block.gray-block.active,
.home_services .block.gray-block:hover,
.team-carousel .block.gray-block:hover,
.list-course .block.gray-block:hover,
.home_services .block.gray-block.active a,
.team-carousel .block.gray-block.active a,
.list-course .block.gray-block.active a,
.home_services .block.gray-block:hover a,
.team-carousel .block.gray-block:hover a,
.list-course .block.gray-block:hover a,
.home_services .block.gray-block.active .title,
.team-carousel .block.gray-block.active .title,
.list-course .block.gray-block.active .title,
.home_services .block.gray-block:hover .title,
.team-carousel .block.gray-block:hover .title,
.list-course .block.gray-block:hover .title {
  transition: all ease .3s;
}
.home_services .block .cover-icon,
.team-carousel .block .cover-icon,
.list-course .block .cover-icon {
  padding: 30px;
  margin: auto;
  max-width: 160px;
}
.home_services .block .cover-image,
.team-carousel .block .cover-image,
.list-course .block .cover-image {
  padding: 0;
  margin: 0px -15px 15px;
  max-width: calc(130%);
  background: #f5f5f5;
  border-radius: 0;
  overflow: hidden;
}
.home_services .block .team-avatar,
.team-carousel .block .team-avatar,
.list-course .block .team-avatar {
  padding: 0;
  width: 70px;
  height: 70px;
  background: #ccc;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  transition: all ease .3s;
  display: inline-block;
  float: left;
}
.home_services .block .title,
.team-carousel .block .title,
.list-course .block .title,
.home_services .block .title h2,
.team-carousel .block .title h2,
.list-course .block .title h2,
.home_services .block .title h3,
.team-carousel .block .title h3,
.list-course .block .title h3,
.home_services .block .title h4,
.team-carousel .block .title h4,
.list-course .block .title h4 {
  font-size: 15px;
  font-weight: 500;
}
.home_services .block .quote-block,
.team-carousel .block .quote-block,
.list-course .block .quote-block {
  display: block;
  width: 100%;
  margin-top: 32px;
  text-align: left;
  font-style: italic;
  padding: 15px 0 0;
  font-size: 15px;
}
.home_services .block .quote-block::before,
.team-carousel .block .quote-block::before,
.list-course .block .quote-block::before,
.home_services .block .quote-block::after,
.team-carousel .block .quote-block::after,
.list-course .block .quote-block::after {
  content: '';
  display: table;
}
.home_services .block .quote-block::after,
.team-carousel .block .quote-block::after,
.list-course .block .quote-block::after {
  clear: both;
}
.home_services .block .hover-block,
.team-carousel .block .hover-block,
.list-course .block .hover-block {
  opacity: 0;
  transition: all ease .3s;
  margin-top: -40px;
  overflow: hidden;
}
.home_services .block .float-button,
.team-carousel .block .float-button,
.list-course .block .float-button {
  position: absolute;
  right: 30px;
  bottom: 0;
  transform: translateY(50%);
}
.home_services .block .float-button.btn:hover,
.team-carousel .block .float-button.btn:hover,
.list-course .block .float-button.btn:hover {
  box-shadow: 0px 6px 12px 0 rgba(0, 0, 0, 0.3);
}
.meta-info {
  margin-top: -5px;
  margin-bottom: 10px;
  font-weight: normal;
}
.meta-info-item {
  font-size: 12px;
  margin-right: 10px;
  color: #999;
  margin-bottom: 10px;
}
.meta-info-item a {
  color: #016833;
}
.block.block-transform {
  overflow: hidden;
  min-height: 50px;
  transform: translateY(-50%);
}
.block.block-higher {
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.2);
}
.block.block-radius {
  border-radius: 8px;
}
.block .block-info {
  border-top: 1px solid #eaeaea;
  background: #f3f3f3;
  border-radius: 0 0 3px 3px;
  margin: 15px -15px -15px;
  padding: 5px 15px 5px;
}
.bg-dark-style {
  position: relative;
  background: #001c0e url(../images/original-background-dark.jpg);
  background-attachment: fixed;
  background-size: 50% auto;
  animation: BackgroundGradient 250s ease infinite;
}
.team-carousel .block .title,
.team-carousel .block .title h2,
.team-carousel .block .title h3,
.team-carousel .block .title h4 {
  margin-bottom: 0;
}
.cover-course {
  background: #016833;
  margin-bottom: -10px;
  padding-bottom: 30px;
  color: #fff;
}
@media (min-width: 768px) {
  .cover-course > .container {
    padding-left: 250px ;
    min-height: 190px;
  }
}
.cover-course .breadcrumb,
.cover-course .breadcrumb span,
.cover-course .breadcrumb a {
  color: #fff;
}
.cover-course .breadcrumb > li > i {
  margin-right: 5px;
}
.cover-course .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.3);
}
.cover-course .page-title {
  border: none;
  font-size: 26px;
  font-weight: 300;
}
.cover-course .page-title,
.cover-course .page-title a {
  color: #fff;
}
.cover-course .page-title > a::before,
.cover-course .page-title > span::before {
  display: none;
}
.aside-course {
  width: 100%;
}
@media (min-width: 768px) {
  .aside-course {
    margin-top: -300px;
    background: #fff;
    width: 220px;
    border-radius: 3px;
  }
}
ul.aside-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.aside-menu > li > a {
  color: #808080;
  display: block;
  padding: 6px 12px;
  font-weight: 400;
  border-bottom: 1px solid #eaeaea;
}
ul.aside-menu > li > a:hover {
  color: #016833;
}
ul.aside-menu > li:last-child > a {
  border-bottom: none;
}
.fancy-button .fancy-button-text {
  white-space: nowrap;
}
.forrent-post {
  padding: 15px 0 0;
  border-bottom: 1px solid #eaeaea;
  font-size: 13px;
}
.forrent-post::before,
.forrent-post::after {
  content: '';
  display: table;
}
.forrent-post::after {
  clear: both;
}
.forrent-post:nth-child(2n) {
  background: #fdfffc;
}
.forrent-post .image-container {
  position: relative;
  float: left;
  max-width: 33.333333%;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
}
.forrent-post .image-container img {
  max-width: 100%;
}
.forrent-post .title {
  font-size: 15px;
  font-weight: 500;
}
.forrent-post .title,
.forrent-post .title a {
  color: #333;
}
.forrent-post strong {
  color: #999;
  font-weight: 500;
}
.rent-attribute {
  white-space: nowrap;
  margin-bottom: 10px;
}
/* SLIDE OWL */
.slide-owl-carousel .owl-dots {
  margin-top: -36px;
  text-align: right;
  padding-right: 64px;
}
.slide-owl-carousel.owl-carousel .owl-nav button.owl-prev,
.slide-owl-carousel.owl-carousel .owl-nav button.owl-next {
  top: calc(100% - 36px);
  font-size: 12px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  color: #353535;
  background: #ececec;
  border-radius: 0;
  box-shadow: none;
  transform: none;
  left: auto;
}
.slide-owl-carousel.owl-carousel .owl-nav button.owl-next {
  right: -5px;
}
.slide-owl-carousel.owl-carousel .owl-nav button.owl-prev {
  right: 26px;
}
