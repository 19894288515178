
/* ==========================================================================
   Navigation
   ========================================================================== */   
//    @primary-color: #f00;
  //  @second-color: lighten(@primary-color, 20);
   .navigation {
    zoom: 1;
    display: inline-block;
    z-index: 99;
    position: relative;
    vertical-align: top;
    // margin-top: 10px; 
  }
  .fa.nav-right {
    background: @primary-color;
    color: #fff;
    min-width: 25px;
    min-height: 25px;
    line-height: 25px;
    border-radius: 100%;
    position: fixed;
    right: 10px;
    top: 10px;
    text-align: center;
  }
  .navigation .navigation_icon {
    display: none;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
  }
  .navigation .navigation_icon:before,
  .navigation .navigation_icon:after {
    content: "  ";
  }
  .navigation .navigation_icon:before,
  .navigation .navigation_icon:after,
  .navigation .navigation_icon > span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: @primary-color;
    margin: 5px auto;
  }
  .navigation:before,
  .navigation:after {
    content: " ";
    display: table;
  }
  .navigation:after {
    clear: both;
  }
  .navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .navigation a {
    text-decoration: none;
  }
  .navigation ul {
    text-align: center;
  }
  .navigation ul > li {
    display: inline-block;
  }
  .navigation ul > li > a,
  .navigation ul > li > span {
    display: block;
    padding: 5px 15px;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    display: block;
    padding: 8px 30px;
  }
  /* PC
  */
  @media (min-width: 768px) {   
    /* BEFORE HEIGHT TO PADDING */
    .navigation > ul > li.hasSub { position: relative; }
    .navigation > ul > li.hasSub:hover:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 15px;
        background: transparent;
        position: absolute; 
        top:100%
    } 
    .fa.nav-right {
      display: none;
    }
    .navigation ul > li .sub-icon {
      display: block;
      position: absolute;
      top: 48%;
      right: 6px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      width: 0;
      height: 0;
      text-indent: -999999px;
      border-top: 4px dashed #999;
      border-top: 4px solid\9;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
    .navigation ul > li > ul > li .sub-icon {
      display: none;
    }
    .navigation ul > li ul {
      position: absolute;
      margin-top: 8px;
      min-width: 180px;
      text-align: left;
      background: #fff;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      display: block;
      visibility: hidden;
      opacity: 0; 
      transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
      backface-visibility: hidden;
          transform: translateY(20px);
      border: 0px solid transparent;
      border-top: 2px solid @second-color;
      z-index: 1;
    }
    .navigation ul > li ul:before {
      content: " ";
      border-bottom: 4px dashed @second-color;
      border-bottom: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: absolute;
      left: 20px;
      top: -6px;
    }
    .navigation ul > li ul > li {
      display: block;
      float: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;
    }
    .navigation ul > li ul > li:last-child {
      border-bottom: none;
    }
    .navigation ul > li:hover > ul {
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
      visibility: inherit;
    }
  
    .navigation ul > li> ul > li ul {
        left:100%;
        top: 0;
        border-top:0;
        border-left: 0px solid #eaeaea;
    }
    .navigation ul > li> ul > li ul:before {
        transform: rotate(-90deg);
        left:-6px;
        top: 3px;
        border-color: transparent  transparent transparent transparent;
    }

    // RIGHT MENU
    .nav-right.navigation ul > li > ul ,
    .pull-right .navigation ul > li > ul {
      left: auto;
      right: 0;
    }
    .nav-right.navigation ul > li ul:before,
    .pull-right .navigation ul > li ul:before{
      left: auto;
      right: 20px;
    }
  }
  /* ----------------
      Mobile
   ------------------*/
  @media (max-width: 768px) {
    .navigation .navigation_icon {
      display: block;
      position: fixed;
      left: 5px;
      top: 5px;
      background: transparent;
      z-index: 9999;
    }
    .nav-right.navigation .navigation_icon { 
      left: auto;
      right: 5px; 
    }
    .navigation > ul {
      position: fixed;
      display: block;
      top: 0;
      left: -220px;
      bottom: 0;
      height: 100%;
      width: 220px;
      background: #fff;
      -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
              box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
              z-index: 9999;
    }
    .nav-right.navigation > ul {
      left: auto;
      right: -220px;
    }
    .navigation > ul > li {
      float: none;
      display: block;
      text-align: left;
    }
    .navigation > ul > li > a,
    .navigation > ul > li > span {
      padding: 10px 30px;
      font-size: 14px;
      border-bottom: 1px solid #eaeaea;
    }
    
    .navigation > ul > li ul {
      text-align: left;
    }
    .navigation > ul > li ul > li {
      display: block;
    }
    .navigation > ul > li ul > li > a {
      padding-left: 30px;
      display: block;
      background: #e8e8e8;
      border-bottom: 1px solid #eaeaea;
    }
    .navigation .navigation_icon:before,
    .navigation .navigation_icon:after,
    .navigation .navigation_icon > span {
      -webkit-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }
    .navigation .navigation_icon:before {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    .navigation .navigation_icon:after {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    .navigation .navigation_icon > span {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg);
      opacity: 1;
    }
    .navigation > ul,
    .navigation .navigation_icon {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .navigation.nav-open > ul {
      left: 0;
      z-index: 9999;
      overflow: auto;
    }
    .nav-right.navigation.nav-open > ul {
      left: auto;
      right: 0;
    }
    .navigation.nav-open .navigation_icon {
      left: 220px;
      z-index: 9999;
    }
    .nav-right.navigation.nav-open .navigation_icon {
      left: auto;
      right: 220px; 
    }
    .navigation.nav-open .navigation_icon:before {
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      margin-top: 10px;
    }
    .navigation.nav-open .navigation_icon:after {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      margin-top: -14px;
    }
    .navigation.nav-open .navigation_icon > span {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      opacity: 0;
    }
  }
  .backdrop_nav {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }
  @media (min-width: 768px) {
    .navigation ul > li .sub-icon {
      display: none;
    }
    li.hasSub > a {
      position: relative;
    }
    li.hasSub > a:after {
      content: "";
      position: relative;
      right: 0px;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 0;
      height: 0;
      display: inline-block;
      margin-left: 4px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
  @media (max-width: 768px) {
    .navigation > ul > li:first-child {
      border-top: 1px solid #eaeaea;
    }
    .navigation .hasSub {
      position: relative;
    }
    .navigation .hasSub > .sub-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 10px;
      padding-left: 17px;
      width: 40px;
      height: 37px;
      background: transparent;
      border-left: 1px solid #eaeaea;
      border-bottom: none;
    }
    .navigation .hasSub > ul {
      display: none;
    }
    .navigation .hasSub.hasSub_on > ul {
      display: block;
    }
    .navigation > ul > li {
      position: relative;
      width: 100%;
    }
    .navigation > ul > li > ul {
      display: none;
      padding: 0;
    }
    .navigation > ul > li > ul li > a {
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
      color: #666;
    }
  }
  @media (max-width: 768px) { 
    .navi-wrapper .container {
        position: relative;
        z-index: 9;
      }
      .navigation>ul {    
        padding-top: 90px !important;
        background: #fff url(../images/logo.png) no-repeat center ~'20px / 70%'  auto;
      }
  }
  
  // NAVIGATION Style layout
  @media (min-width: 768px) {
    .navi-wrapper .container {
      position: relative;
      z-index: 9;
    }
    // .navi-wrapper { 
    //   background-color: @primary-color;
    // }
    .navigation > ul > li {
      margin-left: -3px;
      &:first-child {
        margin-left: 0;
      }
    }
    .navigation > ul > li > a,
    .navigation > ul > li > span {
      padding: 10px;
      // letter-spacing: -.8px;
      // font-size: 14px;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 0px;
        margin: auto;
        height: 2px;
        background: @second-color; 
        transition: all .3s ease;
      }
    }
    .navigation > ul > li.active > a,
    .navigation > ul > li.active > span,
    .navigation > ul > li > a:hover,
    .navigation > ul > li > span:hover {      
      background: transparent;
      &::before {width: 100%;}
    }
      .navigation > ul > li.hasSub.mega-menu {
        // position: static;
        >ul {
          left: 0;
          width: 400px;
          // min-height: 60px;
          >li {
            display: block;
            float: left;
            width: ~"calc(200px - 0px)";
          }
        }
        &.three-items-menu {
          >ul {
            width: 600px;
            >li {
              width: ~"calc(200px - 0px)";
            }
          }
        }
      }
  }
  
  
@media (min-width: 768px) {
  .navigation > ul > li  a,
  .navigation > ul > li  span {
    color: #333;
    transition: all .3s ease;
  }
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    text-transform: uppercase;
    font-weight: 500;
  }
  .navigation > ul > li > ul> li  a:hover,
  .navigation > ul > li > ul> li  span:hover {
    color: #fff;
    background: @second-color;
  }
  .navigation > ul > li > a:hover,
  .navigation > ul > li > span:hover {
    color: #666;
    // background: @second-color;
  }
}
@media (max-width: 1000px) {
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 10px 5px;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .navigation > ul > li > a,
  .navigation > ul > li > span {
    padding: 8px 5px;
    color: @primary-color;
    font-size: 14px;

    color: #333;
  }
}