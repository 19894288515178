.partners {
    background: transparent;
}
.owl-carousel .owl-item .partner-item {
    text-align: center; 
    display: block;
    img {
        max-width: 100%; 
        display: block;
        margin: auto;
    }
}