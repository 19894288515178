
.pagination .page-item.active .page-link {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    -webkit-border-radius: .125rem;
    border-radius: .125rem;
    background-color: #4285f4;
    color: #fff
}

.pagination .page-item.active .page-link:hover {
    background-color: #4285f4
}

.pagination .page-item.disabled .page-link {
    color: #868e96
}

.pagination .page-item .page-link {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    outline: 0;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    color: #212529
}

.pagination .page-item .page-link:hover {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    -webkit-border-radius: .125rem;
    border-radius: .125rem;
    background-color: #eee
}

.pagination .page-item .page-link:focus {
    background-color: transparent
}

.pagination.pagination-lg .page-item .page-link {
    font-size: 1rem
}

.pagination.pagination-sm .page-item .page-link {
    font-size: .8rem
}

.pagination.pagination-circle .page-item .page-link {
    margin-left: 2px;
    margin-right: 2px;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.pagination.pagination-circle .page-item .page-link:hover {
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.pagination.pagination-circle .page-item.active .page-link {
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.pagination.pg-blue .page-item.active .page-link {
    background-color: #4285f4
}

.pagination.pg-blue .page-item.active .page-link:hover {
    background-color: #4285f4
}

.pagination.pg-red .page-item.active .page-link {
    background-color: #ff3547
}

.pagination.pg-red .page-item.active .page-link:hover {
    background-color: #ff3547
}

.pagination.pg-teal .page-item.active .page-link {
    background-color: #2bbbad
}

.pagination.pg-teal .page-item.active .page-link:hover {
    background-color: #2bbbad
}

.pagination.pg-dark-grey .page-item.active .page-link {
    background-color: #37474f
}

.pagination.pg-dark-grey .page-item.active .page-link:hover {
    background-color: #37474f
}

.pagination.pg-dark .page-item.active .page-link {
    background-color: #2e2e2e
}

.pagination.pg-dark .page-item.active .page-link:hover {
    background-color: #2e2e2e
}

.pagination.pg-blue-grey .page-item.active .page-link {
    background-color: #3f729b
}

.pagination.pg-blue-grey .page-item.active .page-link:hover {
    background-color: #3f729b
}

.pagination.pg-amber .page-item.active .page-link {
    background-color: #ff6f00
}

.pagination.pg-amber .page-item.active .page-link:hover {
    background-color: #ff6f00
}

.pagination.pg-purple .page-item.active .page-link {
    background-color: #5e35b1
}

.pagination.pg-purple .page-item.active .page-link:hover {
    background-color: #5e35b1
}
