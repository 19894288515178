.slide-owl-carousel {
    border-bottom: 1px solid #eaeaea; 
    margin-bottom: 10px;

}
.main-head .block {
    padding: 15px; 
    display: block;
    .cover-item {
        width: 100%;
        display: inline-block;
        float: left;
        margin-bottom: 15px;
    }
    img {
        max-width: 100%; 
        display: block;
        margin: auto;
    }
    .title {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        margin-bottom: 4px;
    }
    .short-description {
        color: #333;
    }
    .detail-item {
        padding: 0;
    }
    @media (min-width: 768px) {
        .cover-item {
            width: 46.666%;
            margin-right: 15px;
        }
    }
}